<template>
    <div>
      <h3>Expérience professionnelle</h3>
      <VeeForm :validation-schema="schema" @submit="onSubmit">
        <div class="mb-3">
          <label for="situation" class="form-label">Situation socioprofessionnelle :</label>
          <Field name="situation" as="select" class="form-select" v-model="localFormData.situation">
            <option value="">Faites un choix</option>
            <option value="Etudiant(e)">Etudiant(e)</option>
            <option value="Professionnel(le)">Professionnel(le)</option>
          </Field>
          <ErrorMessage name="situation" class="text-danger" />
        </div>
        <!-- Champs conditionnels pour étudiant -->
        <template v-if="localFormData.situation === 'Etudiant(e)'">
          <div class="mb-3">
            <label for="niveau_etude" class="form-label">Niveau d'étude :</label>
            <Field name="niveau_etude" type="text" class="form-control" v-model="localFormData.niveau_etude" />
            <ErrorMessage name="niveau_etude" class="text-danger" />
          </div>
  
          <div class="mb-3">
            <label for="domaine_formation" class="form-label">Domaine de formation :</label>
            <Field name="domaine_formation" type="text" class="form-control" v-model="localFormData.domaine_formation" />
            <ErrorMessage name="domaine_formation" class="text-danger" />
          </div>
  
          <div class="mb-3">
            <label for="specialite" class="form-label">Spécialité :</label>
            <Field name="specialite" type="text" class="form-control" v-model="localFormData.specialite" />
            <ErrorMessage name="specialite" class="text-danger" />
          </div>
  
          <div class="mb-3">
            <label for="entite_formation" class="form-label">Entité de formation :</label>
            <Field name="entite_formation" type="text" class="form-control" v-model="localFormData.entite_formation" />
            <ErrorMessage name="entite_formation" class="text-danger" />
          </div>
        </template>
  
        <!-- Champs conditionnels pour professionnel -->
        <template v-if="localFormData.situation === 'Professionnel(le)'">
          <div class="mb-3">
            <label for="secteur_activite" class="form-label">Secteur d'activité :</label>
            <Field name="secteur_activite" type="text" class="form-control" v-model="localFormData.secteur_activite" />
            <ErrorMessage name="secteur_activite" class="text-danger" />
          </div>
  
          <div class="mb-3">
            <label for="details_activite" class="form-label">Détails sur l'activité :</label>
            <Field name="details_activite" as="textarea" class="form-control" v-model="localFormData.details_activite" />
            <ErrorMessage name="details_activite" class="text-danger" />
          </div>
  
          <div class="mb-3">
            <label for="niveau_etude_prof" class="form-label">Niveau d'étude :</label>
            <Field name="niveau_etude_prof" type="text" class="form-control" v-model="localFormData.niveau_etude_prof" />
            <ErrorMessage name="niveau_etude_prof" class="text-danger" />
          </div>
        </template>

        <fieldset>
        <div class="mb-3">
          <label for="logiciel_ca" class="form-label">Intitulé de la formation:</label>
          <Field name="logiciel_ca" as="select" class="form-select" v-model="localFormData.logiciel_ca">
            <option value="">Sélectionnez la formation</option>
            <option value="GMAO-Gestion de la maintenance assistée par Ordinateur">Gestion de la maintenance assistée par Ordinateur GMAO</option>
            <option value="FM-Fabrications Mécanique: Métaux et bois">Fabrications Mécanique: Métaux et bois</option>
            <option value="PR-Prototypage Rapide">Prototypage Rapide</option>
          </Field>
          <ErrorMessage name="logiciel_ca" class="text-danger" />
        </div>
      </fieldset>

      <fieldset>
        <div class="mb-3">
          <label for="entreprise" class="form-label">Avez-vous créé une entreprise ?</label>
          <Field name="entreprise" as="select" class="form-select" v-model="localFormData.entreprise">
            <option value="">Sélectionnez</option>
            <option value="oui">Oui</option>
            <option value="non">Non</option>
          </Field>
          <ErrorMessage name="entreprise" class="text-danger" />
        </div>
      </fieldset>

      <fieldset v-if="localFormData.entreprise === 'oui'">
        <div class="mb-3">
          <label for="entreprise_nom" class="form-label">Veuillez donner son nom et son adresse :</label>
          <Field name="entreprise_nom" as="textarea" class="form-control" v-model="localFormData.entreprise_nom" />
          <ErrorMessage name="entreprise_nom" class="text-danger" />
        </div>
      </fieldset>

      <fieldset>
        <div class="mb-3">
          <label for="structure" class="form-label">Travaillez-vous dans une structure ?</label>
          <Field name="structure" as="select" class="form-select" v-model="localFormData.structure">
            <option value="">Sélectionnez</option>
            <option value="oui">Oui</option>
            <option value="non">Non</option>
          </Field>
          <ErrorMessage name="structure" class="text-danger" />
        </div>
      </fieldset>

      <fieldset v-if="localFormData.structure === 'oui'">
        <div class="mb-3">
          <label for="structure_travail" class="form-label">Laquelle ?</label>
          <Field name="structure_travail" type="text" class="form-control" v-model="localFormData.structure_travail" />
          <ErrorMessage name="structure_travail" class="text-danger" />
        </div>

        <div class="mb-3">
          <label for="secteur_activite_exp" class="form-label">Secteur d'activité :</label>
          <Field name="secteur_activite_exp" as="select" class="form-select" v-model="localFormData.secteur_activite_exp">
            <option value="">Sélectionnez</option>
            <option value="agriculture">Agriculture</option>
            <option value="sante">Santé</option>
            <option value="industrie">Industrie</option>
            <option value="btp">BTP</option>
            <option value="biotechnologie">Biotechnologie</option>
            <option value="agroalimentaire">Agroalimentaire</option>
            <option value="commerce">Commerce</option>
            <option value="service_financiers">Service financiers</option>
            <option value="assurance">Assurance</option>
            <option value="autres">Autres</option>
          </Field>
          <ErrorMessage name="secteur_activite_exp" class="text-danger" />
        </div>
      </fieldset>

      <fieldset>
        <div class="mb-3">
          <label for="produit" class="form-label">Avez-vous un produit ou une idée d'innovation à votre actif ?</label>
          <Field name="produit" as="select" class="form-select" v-model="localFormData.produit">
            <option value="">Sélectionnez</option>
            <option value="oui">Oui</option>
            <option value="non">Non</option>
          </Field>
          <ErrorMessage name="produit" class="text-danger" />
        </div>
      </fieldset>

      <fieldset v-if="localFormData.produit === 'oui'">
        <div class="mb-3">
          <label for="produit_innovation" class="form-label">Lequel ?</label>
          <Field name="produit_innovation" as="textarea" class="form-control" v-model="localFormData.produit_innovation" />
          <ErrorMessage name="produit_innovation" class="text-danger" />
        </div>
      </fieldset>
  
        <button type="button" class="btn btn-secondary me-2" @click="$emit('prev')">Précédent</button>
        <button type="submit" class="btn btn-primary">Suivant</button>
      </VeeForm>
    </div>
  </template>
  
  <script>
  import { Form as VeeForm, Field, ErrorMessage } from 'vee-validate'
  import { defineRule } from 'vee-validate'
  import { required } from '@vee-validate/rules'
  
  defineRule('required', required)
  defineRule("required_if_yes", (value, [inputField]) => {
  if (inputField == "oui") {
    if (!value) {
      return "Ce champs est obligatoire";
    }
  }

  return true;
});defineRule("required_if_student", (value, [inputField]) => {
  if (inputField == "Etudiant(e)") {
    if (!value) {
      return "Ce champs est obligatoire";
    }
  }

  return true;
});

defineRule("required_if_professional", (value, [inputField]) => {
  if (inputField == "Professionnel(le)") {
    if (!value) {
      return "Ce champs est obligatoire";
    }
  }

  return true;
});

  export default {
    name: 'ExperienceProfessionnelle',
    components: {
      VeeForm,
      Field,
      ErrorMessage
    },
    props: ['formData'],
    emits: ['update:formData', 'next', 'prev'],
    data() {
      return {
        localFormData: { ...this.formData },
        schema: {
            situation: 'required',
      niveau_etude: 'required_if_student:@situation',
      domaine_formation: 'required_if_student:@situation',
      specialite: 'required_if_student:@situation',
      entite_formation: 'required_if_student:@situation',
      secteur_activite:'required_if_professional:@situation',
      details_activite:'required_if_professional:@situation',
      niveau_etude_pro:'required_if_professional:@situatin',
      logiciel_ca:'required',
      logiciel_ca_nom:'required_if_yes:@logiciel_ca',
      niveau_maitrise_3d:'required_if_yes:@logiciel_ca',
      entreprise:'required',
      entreprise_nom:'required_if_yes:@entreprise',
      structure:'required',
      structure_travail: 'required_if_yes:@structure',
      secteur_activite_exp: 'required_if_yes:@structure',
      produit: 'required',
      produit_innovation: 'required_if_yes:@produit',
        }
      }
    },
    methods: {
      onSubmit() {
        this.$emit('update:formData', this.localFormData)
        this.$emit('next')
      }
    }
  }
  </script>